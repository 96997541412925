import React from 'react';
import styled from "styled-components";
import TwitterIcon from "../images/social-icons/twitter-black.svg";
import LinkedInIcon from "../images/social-icons/linkedin-black.svg";
import FacebookIcon from "../images/social-icons/facebook-black.svg";
import LinkIcon from "../images/social-icons/link-black.svg";

const ShareSocialBarWrapper = styled.div`
    width: 125px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0 0;
`
const SocialIcon = styled.img`
    width: 18px;
    height: 18px;
    filter: invert(87%) sepia(0%) saturate(764%) hue-rotate(234deg) brightness(78%) contrast(100%);
    cursor: pointer;
`

const ShareSocialBar = () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url).then(function () {
            alert("Link copied");
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    return (
        <ShareSocialBarWrapper>
            <a href={`https://twitter.com/intent/tweet?text=${url} `}><SocialIcon src={TwitterIcon} alt="twitter icon" /></a>
            <a href={`https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${url}`}><SocialIcon src={LinkedInIcon} alt="linkedin icon" /></a>
            <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}><SocialIcon src={FacebookIcon} alt="facebook icon" /></a>
            <SocialIcon onClick={copyToClipboard} src={LinkIcon} alt="copy link icon" />
        </ShareSocialBarWrapper>
    )
}

export default ShareSocialBar;
