import dayjs from 'dayjs';
import { graphql } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from 'react';
import styled from "styled-components";
import Header from '../components/header';
import hljs from 'highlight.js';
import { Helmet } from "react-helmet";
import favicon from '../images/favicon.png';
import ShareSocialBar from '../components/share-social-bar';

// Images
const TopRightTree = styled.div`
  position: absolute;
  top: -19px;
  right: -250px;
  width: 950px;
  z-index: 1;
 
   @media (max-width: 768px) {
    right: -265px;
    width: 350px !important;
  }

  @media (max-width:1180px ) {
    width: 660px;
  }

  @media (max-width: 900px) {
    width: 500px;
    right: -245px;
  }
 `;

const LeftUnderHeroTree = styled.div`
  position: absolute;
  top: 650px;
  left: -380px;
  width: 915px;
  z-index: 0;

@media (max-width: 768px) {
  left: -265px;
  width: 615px;
  top: 800px;
}

@media (max-width: 1150px) {
 top: 900px; 
}
`;

const RightBottomTree = styled.div`
   position: absolute;
   top: 2700px;
   right: -380px;
   width: 900px;
   z-index: 0;

   @media (max-width: 768px) {
     right: -286px;
     width: 615px;
  }
 `;

const BlogPostWrapper = styled.div`
   background-color: white;
   margin: 0;
   overflow: hidden;
   position: relative;
   min-height: 100vh;
`;

const BlogPostInner = styled.div`
      max-width: 1060px; 
   margin-left: auto;
   margin-right: auto;
   padding: 180px 80px 180px 80px;
   z-index: 3;
   position: relative;
   height: 100%;



   @media (max-width: 768px) {
     max-width: 100%;
     padding: 0 36px 0px 36px;
     margin-top: 86px;
     margin-bottom: 160px;
  }

 `
const Title = styled.h2`
  margin-bottom: 6px;
  font-size: 28px;
  margin-top: 0;
  line-height: 34px;
`
const Subtitle = styled.p`
  color: gray;
  margin: 0;
  line-height: 28px;  
  font-size: 16px;
  margin-top: 16px;
  font-weight: 400;
  max-width: 760px;
`

const CoverImage = styled.img`
  height: auto;
  width: 100%;
  margin-top: 2em;
`

const BlogPostContent = styled.section`
  margin-top: 2em;
  width: 100%;

    .full-width {
      width: 100%;
      height: 100%;
    }

   p {
     font-size: 16px;
     line-height: 28px;
   }

   blockquote {
     font-size: 14px;
     line-height: 23px;
     color: gray;
   }

   .small-text {
     * {
       font-size: 12px;
      }
   }
   
  @media (max-width: 500px) {
    .small-text { // this custom class comes from the cms
      max-width: 300px;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
    }
    pre {
      font-size: 14px;
      width: 100%;
    }
  }

`
const AuthorDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 325px;
  height: 45px;
  margin-bottom: 2em;
`

const AuthorImage = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 45px;
`

const AuthorName = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: -10px;
`
const Date = styled.p`
  font-size: 12px;
  color: gray;
  margin: 0;
`
function BlogPostTemplate({ data }) {
  const post = data.gcms.post;

  useEffect(() => {
    hljs.configure({
      languages: ['typescript', 'javascript', 'bash', 'shell']
    })
    hljs.highlightAll();
  }, [post])

  return (
    <BlogPostWrapper>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <title>SpringTree</title>
        <link rel="canonical" href="https://springtree.eu" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.5.0/styles/atom-one-dark.min.css" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@SpringTreeNews" />
        <meta name="twitter:creator" content="@SpringTreeNews" />
        <meta property="og:title" content={post.title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.coverImage.url} />
        <meta property="og:locale" content="en_US"></meta>
      </Helmet>

      <TopRightTree >
        <StaticImage placeholder="none" src='../images/top-right-tree.png' alt="leaves" />
      </TopRightTree>
      <LeftUnderHeroTree>
        <StaticImage placeholder="none" src="../images/left-under-hero-tree.png" alt="leaves" />
      </LeftUnderHeroTree>
      <RightBottomTree>
        <StaticImage loading="eager" placeholder="none" src="../images/mid-right-tree.png" alt="leaves" />
      </RightBottomTree>

      <BlogPostInner>
        <AuthorDiv>
          <AuthorImage src={post.author.picture.url} />
          <div style={{ marginLeft: 16 }}>
            <AuthorName>{post.author.name}</AuthorName>
            <Date>{dayjs(post.date).format("MMM DD")}	<span style={{ fontSize: 7, margin: '0 6px 6px 6px' }}>&#9679;</span> {post.timeToRead} min read</Date>
          </div>

        </AuthorDiv>

        <Title>{post.title}</Title>
        <Subtitle>{post.excerpt}</Subtitle>

        <ShareSocialBar />
        <CoverImage src={post.coverImage.url} />

        <BlogPostContent>
          <div dangerouslySetInnerHTML={{ __html: post.content.html }} />
        </BlogPostContent>
      </BlogPostInner>
    </BlogPostWrapper>
  )
}

export const dataQuery = graphql`
    query ($slug: String!, $language: String!) {
			locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
     gcms {
        post(where: {slug: $slug}) {
            id
            slug
            title
            content {
                html
            }
            tags
            seo {
                title
            }
            excerpt
            coverImage {
                url
            }
            date
            timeToRead
            author {
                name
                title
                picture {
                    url
                }

            }
        }
    }
}
`;

export default BlogPostTemplate